import React, { useEffect, useState } from 'react';
import {useNavigate, useRoutes} from 'react-router-dom';
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import AuthService from "../service/AuthService";

export default function Routes() {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const checkAuth = async () => {
            const authInfo = sessionStorage.getItem('authInformation');
            if (authInfo) {
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
                navigate('/login');
            }
        };

        checkAuth();
    }, [navigate]);
    const routes = isAuthenticated ? [PrivateRoutes] : [PublicRoutes];
    return useRoutes(routes);
}
