import {createSlice, PayloadAction} from "@reduxjs/toolkit"

type AgencyState = {
    agencyId: string | null
}

const initialState = {
    agencyId: null,
} as AgencyState

export const agency = createSlice({
    name: "agency",
    initialState,
    reducers: {
        reset: () => initialState,
        resetAgencyId: (state) => {
            state.agencyId = null;
        },
        setAgencyIdAction: (state, action: PayloadAction<string | null>) => {
            state.agencyId = action.payload
        },
    },
})

export const {resetAgencyId, reset, setAgencyIdAction} = agency.actions
export default agency.reducer
