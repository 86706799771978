// ================= Library ================= //
import React, {useState} from "react"
import {Button, Form, Input, Layout, Space, Typography, Image} from "antd"
import {LockOutlined, UserOutlined} from "@ant-design/icons"
import { useNavigate } from 'react-router-dom';

// ================= Style ================= //
import "./index.scss"

// ================= Component ================= //
import logo from "../../assets/logo.png"
import {useAppDispatch} from "../../store/hooks";
import {hideLoading, showLoading} from "../../store/slices/loading";
import AuthApi from "../../service/api/AuthApi";
import {loginSuccess} from "../../store/slices/auth";
import {setAgencyIdAction} from "../../store/slices/agency";

// ================= Type ================= //
const {Title} = Typography

export default function LoginPage() {
    // ================= State ================= //
    const [loading, setLoading] = useState(false)

    // ================= Form ================= //
    const [form] = Form.useForm()
    const values = Form.useWatch([], form)

    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    // ================= Function ================= //
    const login = async () => {
        try {
            dispatch(showLoading({}));
            const res = await AuthApi.login(values.email, values.password);
            const access_token = res?.data?.access_token;
            dispatch(loginSuccess(access_token));
            await getCurrentUser(access_token);
            // redirect to dashboard
            navigate('/dashboard');
            console.log('access_token', access_token)
            dispatch(hideLoading());
        } catch (err) {
            console.log("error", err);
            dispatch(hideLoading());
        }
    }

    const getCurrentUser = async (token: string) => {
        try {
            dispatch(showLoading({}));
            const res = await AuthApi.getCurrentUser({
                token: token
            });
            dispatch(setAgencyIdAction(res?.data?.agency_id));
            dispatch(hideLoading());
        } catch (err) {
            console.log("error", err);
            dispatch(hideLoading());
        }
    }

    // ================= Render ================= //
    return (
        <Layout className="login-layout page-container">
            <Space className="header">
                <Image src={logo} width={36} height={36} alt="" className="logo"/>
                <Title level={4}>Ordera</Title>
            </Space>

            <div className="login-form">
                <Form
                    form={form}
                    disabled={loading}
                    name="normal_login"
                    className="form"
                    layout="vertical"
                    requiredMark={false}
                    onFinish={login}
                >
                    <Title className="title" level={3}>
                        Login
                    </Title>

                    {/*<Form.Item*/}
                    {/*    name="agency"*/}
                    {/*    label="Agency"*/}
                    {/*    rules={[*/}
                    {/*        {*/}
                    {/*            required: true,*/}
                    {/*            message: "Please input your agency!",*/}
                    {/*        },*/}
                    {/*    ]}*/}
                    {/*>*/}
                    {/*    <Input prefix={<ShopOutlined className="site-form-item-icon" />} placeholder="Agency name" />*/}
                    {/*</Form.Item>*/}

                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                            {
                                required: true,
                                message: "Please input your email!",
                            },
                        ]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Email"/>
                    </Form.Item>

                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                            {
                                required: true,
                                message: "Please input your password!",
                            },
                        ]}
                    >
                        <Input.Password
                            prefix={<LockOutlined className="site-form-item-icon"/>}
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </div>

            {/*<FooterContainer layout={0} />*/}
        </Layout>
    )
}
