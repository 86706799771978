// ================= Library ================= //
import React, {useEffect} from "react"
// ================= Style ================= //
import {useAppDispatch} from "../../store/hooks";
import {hideLoading, showLoading} from "../../store/slices/loading";
import AuthService from "../../service/AuthService";
import {useNavigate} from 'react-router-dom';
import {logout} from "../../store/slices/auth";
import {resetAgencyId} from "../../store/slices/agency";
import {resetShopId} from "../../store/slices/shop";
import {resetTime} from "../../store/slices/time";

// ================= Type ================= //

export default function Logout() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(showLoading({}));
        // TO-DO: API Logout
        AuthService.clearToken();
        dispatch(logout());
        dispatch(resetAgencyId());
        dispatch(resetShopId());
        dispatch(resetTime());
        dispatch(hideLoading());
        navigate('/login');
    }, []);

    return null;
}
