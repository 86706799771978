class AuthService {
    public token;

    constructor() {
        const authInfo = sessionStorage.getItem('authInformation');
        this.token = authInfo
    }

    isLogged() {
        return !!this.token;
    }

    setToken(token: string) {
        this.token = token;
    }

    getToken() {
        return this.token;
    }


    clearToken() {
        this.token = null;
        sessionStorage.removeItem('authInformation');
    }
}

export default new AuthService();
