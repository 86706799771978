import {Suspense} from "react";

// lazy loading

const Loadable = (Component: any) => (props: any) => (
    <Suspense>
        <Component {...props}/>
    </Suspense>
)

export default Loadable;
