import {STORE_KEY} from "../constants/store-key";
import './CryptoService'
import CryptoService from "./CryptoService";

class StorageService {

    /**
     * Lấy giá trị từ session storage
     */
    public getFromSession(key: string): any {
        return sessionStorage.getItem("authInformation");
    }

    /*=== Session Storage ===*/
    /**
     * Lưu giá trị vào session storage
     */
    public storeInSession(key: string, data: string): void {
        sessionStorage.setItem(key, data);
    }

    public getUserInfo() {
        const user = CryptoService.decryptText(this.getFromSession(STORE_KEY.authInformation));
        return user;
    }

    public setUserInfo(user: any) {
        try {
            if (user) {
                const value = CryptoService.encryptText(user);
                return this.storeInSession(STORE_KEY.authInformation, value);
            } else {
                this.remove(STORE_KEY.authInformation);
            }
        } catch (err) {
            console.log(`Error set`, err)
        }
    }

    /**
     * Xóa dữ liệu theo key
     */
    public remove(key: any) {
        localStorage.removeItem(key);
        sessionStorage.removeItem(key);
    }
}

export default new StorageService();
