import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { useTheme } from '@mui/material';
import { Box, Toolbar, useMediaQuery } from '@mui/material';
import Header from './Header/Header';
import Drawer from './Drawer/Drawer';
import {openDrawer} from '../store/slices/menu';
import {RootState} from "../store";

const PrivateLayout = () => {
    const theme = useTheme();
    const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
    const dispatch = useAppDispatch();

    // Correctly access the `menu` slice of the state
    const drawerOpen = useAppSelector((state: RootState) => state?.menuReducer?.drawerOpen);

    const [open, setOpen] = useState(drawerOpen);

    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer({ drawerOpen: !open }));
    };

    useEffect(() => {
        setOpen(!matchDownLG);
        dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    }, [matchDownLG]);

    useEffect(() => {
        setOpen(drawerOpen);
    }, [drawerOpen]);

    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <Header open={open} handleDrawerToggle={handleDrawerToggle} />
            <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
            <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
                <Toolbar />
                <Outlet />
            </Box>
        </Box>
    );
};

export default PrivateLayout;
