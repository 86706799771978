class CryptoService {
    private secret: string = '0PpBiDv2oAZ';

    /**
     * DECRYPT TEXT
     * **/
    public decryptText(text: any) {
        try {
            if (!text || typeof text !== 'string') {
                return text;
            }
            text = decodeURIComponent(escape(window.atob(text)));
            text = Array.from(text).reverse();
            text = text.join('');
            return decodeURIComponent(escape(window.atob(text)));

        } catch (err) {
            return text;
        }
    }

    /**
     * ENCRYPT TEXT
     * **/
    public encryptText(text: any) {
        try {
            if (!text || typeof text !== 'string') {
                return text;
            }
            text = window.btoa(unescape(encodeURIComponent(text)));
            text = Array.from(text).reverse();
            text = text.join('');
            return window.btoa(unescape(encodeURIComponent(text)));
        } catch (error) {
            return text;
        }
    }


}

export default new CryptoService();
