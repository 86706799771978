import React, { useState, useEffect } from 'react';
import {
    Grid,
    Stack,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    useMediaQuery,
    useTheme,
    Button
} from '@mui/material';
import ShopApi from "../../service/api/ShopApi";
import { useAppDispatch } from "../../store/hooks";
import { resetShopId, setShopIdAction } from "../../store/slices/shop";

const HeaderContent = () => {
    const dispatch = useAppDispatch();
    const [shops, setShops] = useState([]);
    const [selectedShop, setSelectedShop] = useState('');
    const [selectedItem, setSelectedItem] = useState('');

    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

    useEffect(() => {
        const getShopsByAgencyId = async () => {
            try {
                const agency_id = 1;
                const res = await ShopApi.getShop({ agency_id: agency_id });
                setShops(res?.data);
            } catch (error) {
                console.log('error fetching shops', error);
            }
        };
        getShopsByAgencyId();
    }, []);

    const handleSelectShop = (shopId: any) => {
        setSelectedShop(shopId);
        setSelectedItem(shopId); // Change color when shop is selected
        dispatch(setShopIdAction(shopId));
    };

    const handleSetOverview = () => {
        setSelectedShop('');
        setSelectedItem('overview'); // Change color when overview is selected
        dispatch(resetShopId());
    };

    const largeButtonStyles = {
        fontFamily: 'nunito',
        fontSize: '10px',
        fontWeight: 'bold',
        color: '#000D0B',
        backgroundColor: '#F7CC5B',
        border: '1px solid #F7CC5B',
        width: 150,
        height: 52,
        padding: '12px 16px',
        gap: 8,
        borderRadius: '12px',
        opacity: 1,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&:hover': {
            backgroundColor: '#DEA100',
            color: '#000D0B'
        }
    };

    const mediumButtonStyles = {
        color: '#000D0B',
        width: 111,
        height: 42,
        padding: '9px 16px',
        gap: 0,
        borderRadius: '4px 0px 0px 0px',
        opacity: 1,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        backgroundColor: '#F7CC5B',
        border: '1px solid #F7CC5B',
        '&:hover': {
            backgroundColor: '#DEA100',
            color: '#000D0B'
        }
    };

    const smallButtonStyles = {
        color: '#000D0B',
        width: 94,
        height: 32,
        padding: '5px 12px',
        gap: 0,
        borderRadius: '4px 0px 0px 0px',
        opacity: 1,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        backgroundColor: '#F7CC5B',
        border: '1px solid #F7CC5B',
        '&:hover': {
            backgroundColor: '#DEA100',
            color: '#000D0B'
        }
    };

    const selectedButtonStyles = {
        ...largeButtonStyles,
        backgroundColor: '#DEA100',
    };

    return (
        <>
            {isLargeScreen ? (
                <Stack direction="row" spacing={2} alignItems="center">
                    <Button
                        variant="contained"
                        sx={selectedItem === 'overview' ? selectedButtonStyles : largeButtonStyles}
                        onClick={handleSetOverview}
                    >
                        Overview
                    </Button>
                    {shops.map((shop: any, index: number) => (
                        <Grid item key={index}>
                            <Button
                                variant="contained"
                                sx={selectedItem === shop.id ? selectedButtonStyles : largeButtonStyles}
                                onClick={() => handleSelectShop(shop?.id)}
                            >
                                {shop?.name}
                            </Button>
                        </Grid>
                    ))}
                </Stack>
            ) : (
                <FormControl fullWidth>
                    <InputLabel id="shop-select-label">Select Shop</InputLabel>
                    <Select
                        labelId="shop-select-label"
                        value={selectedShop}
                        label="Select Shop"
                        onChange={(event) => handleSelectShop(event.target.value)}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {shops.map((shop: any) => (
                            <MenuItem key={shop.id} value={shop.id}>
                                {shop.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        </>
    );
};

export default HeaderContent;
