import {configureStore} from "@reduxjs/toolkit"
import shopReducer from "./slices/shop"
import menuReducer from "./slices/menu"
import timeReducer from "./slices/time"
import revenueOrderReducer from "./slices/revenue-order"
import loadingReducer from "./slices/loading"
import authReducer from "./slices/auth";
import agencyReducer from "./slices/agency"
import resetReducer from './slices/core';
import {setupListeners} from "@reduxjs/toolkit/query"

export const store = configureStore({
    reducer: {
        shopReducer,
        menuReducer,
        timeReducer,
        revenueOrderReducer,
        loadingReducer,
        authReducer,
        agencyReducer,
        resetReducer
    }
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
