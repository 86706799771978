// material-ui
import { useTheme } from '@mui/material/styles';
import logo from "../assets/logo.png";

const Logo = () => {
    const theme = useTheme();

    return (

        <img src={logo} alt="Ordera" style={{ height: '60px', width: 'auto' }} />

    );
};

export default Logo;
