import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {Providers} from './store/provider'

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Providers>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Providers>
    </React.StrictMode>
);

reportWebVitals();
