import DashboardIcon from '@mui/icons-material/Dashboard';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import LogoutIcon from '@mui/icons-material/Logout';
// icons
const icons = {
    DashboardIcon,
    AnalyticsIcon,
    LogoutIcon
}

const page = {
    id: 'page',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.DashboardIcon
        },
        {
            id: 'analytic',
            title: 'Analytic',
            type: 'item',
            url: '/analytics',
            icon: icons.AnalyticsIcon
        },
        {
            id: 'logout',
            title: 'Logout',
            type: 'item',
            url: '/logout',
            icon: icons.LogoutIcon
        }
    ]
};

export default page;
