import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {Shop} from "../../models/shop"

type ShopState = {
    shops: Shop[]
    shop: Shop | null
    shopId: string | null
}

const initialState = {
    shops: [],
    shop: null,
    shopId: null,
} as ShopState

export const shop = createSlice({
    name: "shop",
    initialState,
    reducers: {
        reset: () => initialState,
        resetShopId: (state) => {
            state.shopId = null;
        },
        setShopsAction: (state, action: PayloadAction<Shop[]>) => {
            state.shops = action.payload
        },
        setShopAction: (state, action: PayloadAction<Shop | null>) => {
            state.shop = action.payload
        },
        setShopIdAction: (state, action: PayloadAction<string | null>) => {
            state.shopId = action.payload
        },
    },
})

export const {resetShopId, reset, setShopsAction, setShopAction, setShopIdAction} = shop.actions
export default shop.reducer
