import {createSlice} from "@reduxjs/toolkit";
import {DEFAULT_TIME_LOADING} from "../../constants/common";

type LoadingState = {
    show: boolean;
    time: number;
}
type InitialState = {
    showDropdown: boolean;
    loadingState: LoadingState;
}
const initialState: InitialState = {
    showDropdown: false,
    loadingState: {
        show: false,
        time: DEFAULT_TIME_LOADING
    }
};

export const loading = createSlice({
    name: "common",
    initialState,
    reducers: {
        onClickDropdown: (state) => {
            state.showDropdown = !state.showDropdown;
        },
        showLoading: (state, action) => {
            state.loadingState = {
                ...action.payload,
                show: true
            }
        },
        hideLoading: (state) => {
            state.loadingState = {
                show: false,
                time: 0
            }
        }
    },
});

export const {onClickDropdown, showLoading, hideLoading} = loading.actions;
export default loading.reducer;

