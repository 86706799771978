export const DEFAULT_TIME_LOADING = 30000;
export const drawerWidth = 200;

export const config = {
    defaultPath: '/dashboard',
    fontFamily: `'Public Sans', sans-serif`,
    i18n: 'en',
    miniDrawer: false,
    container: true,
    mode: 'light',
    presetColor: 'default',
    themeDirection: 'ltr'
};

