import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import dayjs from 'dayjs';

type TimeState = {
    startDate: any
    endDate: any
}

const initialState = {
    startDate: dayjs().format('YYYY-MM-DD'),
    endDate:  dayjs().format('YYYY-MM-DD'),
} as TimeState

export const time = createSlice({
    name: "time",
    initialState,
    reducers: {
        resetTime: () => initialState,
        setStartDateAction: (state, action: PayloadAction<any>) => {
            state.startDate = action.payload
        },

        setEndDateAction: (state, action: PayloadAction<any>) => {
            state.endDate = action.payload
        }
    },
})

export const {resetTime, setStartDateAction, setEndDateAction} = time.actions
export default time.reducer
