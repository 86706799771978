import {createSlice, PayloadAction} from "@reduxjs/toolkit"

type RevenueState = {
    revenueAndTotalOrder: null,
}

const initialState = {
    revenueAndTotalOrder: null,
} as RevenueState

export const revenueOrder = createSlice({
    name: "revenueOrder",
    initialState,
    reducers: {
        reset: () => initialState,
        setOrderRevenueAction: (state, action: PayloadAction<any>) => {
            state.revenueAndTotalOrder = action.payload
        },
    },
})

export const {reset, setOrderRevenueAction} = revenueOrder.actions
export default revenueOrder.reducer
