import {lazy} from 'react';
import Loadable from "../components/Loadable";
import PrivateLayout from "../layout/PrivateLayout";
import Logout from "../pages/authentication/Logout";
import NotFound from "../pages/error/ErrorPage";

// lazy load
const DashboardPage = Loadable(lazy(() => import('../pages/dashboard/DashBoardPage')))
const AnalyticPage = Loadable(lazy(() => import('../pages/analytics/AnalyticPage')))


const PrivateRoutes = {
    path: '/',
    element: <PrivateLayout/>,
    children: [
        {
            path: '/dashboard',
            element: <DashboardPage/>
        },
        {
            path: '/analytics',
            element: <AnalyticPage/>
        },
        {
            path: '/logout',
            element: <Logout/>
        },
        {
            path: '*',
            element: <NotFound/>
        }
    ]
}

export default PrivateRoutes;
