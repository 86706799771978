import {Box, Typography} from '@mui/material';
import NavGroup from './NavGroup';
import menuItem from '../../../menu-item/index';


const Navigation = () => {
    const navGroups = menuItem.items.map((item: any) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item}/>;
            default:
                return (
                    <div key={item.id} color="error">
                        Data Analytic
                    </div>
                );
        }
    });

    return <Box sx={{pt: 2}}>{navGroups}</Box>;
};

export default Navigation;
