import API from "../ApiService";

class AuthApi {
    login = (email: string, password: string) => {
        return API.post("/auth/login/", {email: email, password: password}, {}, {});
    }
    getCurrentUser = (queryParams?: {
        token?: string
    }) => {
        return API.get("/auth/user/", {}, queryParams)
    }
}

export default new AuthApi();
