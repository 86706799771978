import { Link } from "react-router-dom";
import {Button, Result} from "antd"

export default function NotFound() {
    return (
        <div>
            <Result
                status="404"
                title="404 | Page Not Found"
                subTitle="Sorry, the page you visited does not exist."
                extra={
                    <Link to="/dashboard">
                        <Button type="primary">Return Home</Button>
                    </Link>
                }
            />
        </div>
    )
}
