import NavCard from './NavCard';
import Navigation from './Navigation';

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => (
    <>
        <Navigation/>
        {/*<NavCard/>*/}
    </>

);

export default DrawerContent;
