import React from 'react';
import { useTheme } from '@mui/material/styles';
import { AppBar, IconButton, Toolbar, useMediaQuery } from '@mui/material';
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';
import MenuIcon from '@mui/icons-material/Menu';

interface HeaderProps {
    open: boolean;
    handleDrawerToggle: () => void;
}

const Header: React.FC<HeaderProps> = ({ open, handleDrawerToggle }) => {
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

    const iconBackColor = '#DEA100';
    const iconBackColorOpen = '#F7CC5B';

    const mainHeader: React.ReactElement = (
        <Toolbar>
            <IconButton
                disableRipple
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
                color="secondary"
                sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor, ml: { xs: 0, lg: -2 }, mr: 2 }}
            >
                <MenuIcon />
            </IconButton>
            <HeaderContent/>
        </Toolbar>
    );

    const appBar = {
        position: 'fixed' as const,
        color: 'inherit' as const,
        elevation: 0,
        sx: {
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
    };

    return (
        <>
            {!matchDownMD ? (
                <AppBarStyled open={open} {...appBar}>
                    {mainHeader}
                </AppBarStyled>
            ) : (
                <AppBar {...appBar}>{mainHeader}</AppBar>
            )}
        </>
    );
};

export default Header;
