import {useAppSelector} from '../../../../store/hooks';
import {Box, List, Typography} from '@mui/material';
import NavItem from './NavItem';
import {RootState} from "../../../../store";

interface MenuItem {
    id: any;
    type: 'item' | 'collapse';
    title?: string;
    children?: MenuItem[];
}

const NavGroup: React.FC<{ item: MenuItem }> = ({item}) => {
    const drawerOpen = useAppSelector((state: RootState) => state?.menuReducer?.drawerOpen);
    const navCollapse = item.children?.map((menuItem: any) => {
        switch (menuItem.type) {
            case 'collapse':
                return (
                    <Typography key={menuItem.id} variant="caption" color="error" sx={{p: 2.5}}>
                        collapse
                    </Typography>
                );
            case 'item':
                return <NavItem key={menuItem.id} item={menuItem} level={1}/>;
            default:
                return (
                    <Typography key={menuItem.id} variant="h6" color="error" align="center">
                        Fix - Group Collapse or Items
                    </Typography>
                );
        }
    });

    return (
        <List
            subheader={
                item.title &&
                drawerOpen && (
                    <Box sx={{pl: 3, mb: 1.5}}>
                        <div color="textSecondary">
                            {item.title}
                        </div>
                    </Box>
                )
            }
            sx={{mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0}}
        >
            {navCollapse}
        </List>
    );
};


export default NavGroup;
