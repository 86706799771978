import React, { FC, useEffect } from "react";
import { hideLoading } from "../../store/slices/loading";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import "./Loading.css";

interface LoadingProps {}

const Loading: FC<LoadingProps> = () => {
    const loading = useAppSelector((state) => state.loadingReducer.loadingState);
    const time = loading.time;
    const show = loading.show;

    const dispatch = useAppDispatch()
    useEffect(()=>{
        if(time> 0){
            setTimeout(()=>{
                if(show){
                    dispatch(hideLoading());
                }
            },time)
        }
    })
    return (
        <>
            { show ? <div className="loading">
                <div
                    className="uil-ring-css"
                    style={{  transform: `scale(0.79)` }}
                >
                    <div></div>
                </div>
            </div> : ''

            }
        </>
    )
};

export default Loading;
