import axios from "axios";
import Storage from "./StorageService";
import Helper from "./HelperService";
import Auth from "./AuthService";
import {STORE_KEY} from "../constants/store-key";

class ApiService {
    // GET REQUEST
    get(endPoint: any, params: any, queryParams: any, config = {}) {
        endPoint = this.makeUrl(endPoint, params, queryParams);
        return this.makeRequest({
            method: 'GET',
            url: endPoint,
            ...config
        });
    }

    // POST REQUEST
    post(endPoint: any, data: any, params: any, queryParams: any) {
        endPoint = this.makeUrl(endPoint, params, queryParams);
        return this.makeRequest({
            method: 'POST',
            url: endPoint,
            data: data,
        });
    }

    makeRequest(config: any) {
        const token = Storage.getFromSession(STORE_KEY.authInformation);
        if (token) {
            config.headers = {
                Authorization: `Bearer ${token}`,
                'X-Request-ID': Helper.makeRequestId()
            };
        } else {
            config.headers = {
                'X-Request-ID': Helper.makeRequestId()
            };
        }

        axios.interceptors.response.use(
            response => {
                return response;
            },
            error => {
                if (!error.response) {
                    error.response = {
                        data: 'System error',
                        status: 500,
                    };
                }

                if (error.response.status === 401) {
                    sessionStorage.clear();
                    Auth.clearToken();
                    throw error;
                }

                return Promise.reject(error);
            },
        );
        config.baseURL = "http://localhost:5600/api/v1";
        // config.baseURL = `${process.env.REACT_PUBLIC_API_URL}`;
        return axios(config);
    }

    makeUrl(endPoint: any, params: any, queryParams: any) {
        if (params && Object.keys(params).length) {
            Object.keys(params).forEach(key => {
                endPoint = endPoint.replace(`:${key}`, params[key]);
            });
        }
        if (queryParams && Object.keys(queryParams).length) {
            let index = 0;
            let query = '';
            for (const key in queryParams) {
                if (queryParams.hasOwnProperty(key)) {
                    index++;
                    if (index === Object.keys(queryParams).length) {
                        query += `${key}=${queryParams[key]}`;
                    } else {
                        query += `${key}=${queryParams[key]}&`;
                    }
                }
            }
            endPoint = `${endPoint}?${query}`;
        }
        return endPoint;
    }
}

export default new ApiService();
