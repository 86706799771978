class HelperService {

    makeRequestId = (length = 6) => {
        const getNow = this.getNow();
        const randomStr = this.randomNumberString(length);
        return `${getNow}${randomStr}`;
    }

    getNow = () => {
        const date = new Date().getTime();
        if (date) {
            return date
        }
    }

    randomNumberString = (length: number, str = '0123456789') => {
        return this.randomString(length, str);
    }

    randomString = (length = 10, str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789') => {
        const crypto = window.crypto;
        const arr = new Uint32Array(1);
        let result = '';
        const characters = str;
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            const index = (Number(crypto.getRandomValues(arr)?.toString()) % 10) / 10;
            result += characters.charAt(Math.floor(index * charactersLength));
        }
        return result;
    }
}


export default new HelperService();
