import {Link} from 'react-router-dom';
import {ButtonBase} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../store/hooks';
import {activeItem, openComponentDrawer, openDrawer} from '../store/slices/menu';
import Logo from "./Logo";

interface LogoSectionProps {
    sx?: React.CSSProperties;
    to?: string;
}

const LogoSection: React.FC<LogoSectionProps> = ({sx, to}) => {
    const {defaultId} = useAppSelector((state: any) => state.menuReducer);
    const dispatch = useAppDispatch();
    return (
        <ButtonBase
            disableRipple
            component={Link}
            onClick={() => dispatch(activeItem({openItem: [defaultId]}))}
            to={!to ? 'dashboard' : to}
            sx={sx}
        >
            <Logo/>
        </ButtonBase>
    );
};

export default LogoSection;
