import {useTheme} from '@mui/material/styles';
import {Stack} from '@mui/material';
import DrawerHeaderStyled from './DrawerHeaderStyled';
import LogoComponent from "../../../components/LogoComponent";

interface DrawerHeaderProps {
    open: boolean;
}

const DrawerHeader: React.FC<DrawerHeaderProps> = ({open}) => {
    const theme = useTheme();

    return (
        <DrawerHeaderStyled theme={theme} open={open}>
            <Stack direction="row" spacing={1} alignItems="center">
                <LogoComponent/>
            </Stack>
        </DrawerHeaderStyled>
    );
};

export default DrawerHeader;
