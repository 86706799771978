import LoginPage from "../pages/authentication/LoginPage";
import { Navigate } from 'react-router-dom';


const PublicRoutes = {
    path: '/',
    children: [
        {
            path: '/',
            element: <Navigate to="/login" />
        },
        {
            path: '/login',
            element: <LoginPage/>
        },
    ]
};

export default PublicRoutes;
