import API from "../ApiService";


class ShopApi {

    getShop = (queryParams?: {
        agency_id: number
    }) => {
        return API.get("/shop/", {}, queryParams)
    }


}

export default new ShopApi();
