import {createSlice} from "@reduxjs/toolkit";

type InitialState = {
    loading: boolean,
    isAuthenticated: boolean,
    accessToken: any;
    userInfo: any;
}
const initialState: InitialState = {
    loading: false,
    isAuthenticated: false,
    accessToken: sessionStorage.getItem("authInformation"),
    userInfo: sessionStorage.getItem("userInfo"),
};

export const auth = createSlice({
    name: "auth",
    initialState,
    reducers: {
        loginRequest: (state) => {
            state.loading = true;
            state.isAuthenticated = false;
        },
        loginSuccess: (state, action) => {
            state.loading = false;
            state.isAuthenticated = true;
            state.accessToken = action.payload;
            sessionStorage.setItem("authInformation", state.accessToken);
        },
        loginFailure: (state) => {
            state.loading = false;
            state.isAuthenticated = false;
        },

        logout: (state) => {
            state.isAuthenticated = false;
            state.accessToken = null;
            sessionStorage.clear();
        }
    },
});

export const {loginRequest, loginSuccess, logout, loginFailure} = auth.actions;
export default auth.reducer;

