import React from 'react';
import './App.css';
import Loading from "./components/Loading/Loading"
import Routes from './routes/index';

function App() {
    return (
        <div className="main">
            <Loading/>
            {/*Using routes folder for private layout and public layout*/}
            <Routes/>
        </div>
    );
}

export default App;
